import { SystemLanguage } from '@zeiss/ng-vis-common/types';
var deDE = {
  eva: {}
};
var enGB = {
  eva: {
    routes: {
      title: 'eVA',
      desc: 'Data Push to eVA Platform',
      sanitize: {
        title: 'Sanitize',
        desc: 'Sanitizer Demo'
      }
    }
  }
};
var esES = {
  eva: {}
};
const EVA_TRANSLATIONS = {
  [SystemLanguage['en-GB']]: {
    ...enGB
  },
  [SystemLanguage['es-ES']]: {
    ...esES
  },
  [SystemLanguage['de-DE']]: {
    ...deDE
  }
};

/**
 * Generated bundle index. Do not edit.
 */

export { EVA_TRANSLATIONS };
